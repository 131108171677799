/*
Homepage
*/
.content.home{

	.home_header{
		margin-bottom: 86px;
		height: 358px;
		background: url(../images/home/title_bg_home.jpg);
		background-repeat: no-repeat;
		background-position: center top;
		background-size: cover;
		padding-top: 46px;

		@include media-breakpoint-up(md) {
			margin-left: -25px;
			margin-right: -25px;
			margin-bottom: 136px;
		}
		@include media-breakpoint-up(lg) {
			height: 458px;
		}

		h2{
			color: #fff;
			line-height: 1.2;
			margin-bottom: 56px;
			font-size: 20px;

			@include media-breakpoint-down(sm) {
				padding-left: 4%;
				padding-right: 4%;
			}
			@include media-breakpoint-up(md) {
				font-size: 26px;
			}
			@include media-breakpoint-up(lg) {
				font-size: 31px;
			}

			&:before,
			&:after{
				display: none;
			}
		}

		.video_wrapper{
			video{
				margin-left: auto;
				margin-right: auto;
			    display: block;
			    width: 90%;
			    max-width: 428px;

			    @include media-breakpoint-up(md) {
			    	width: 100%;
					max-width: 428px;
				}
			    @include media-breakpoint-up(lg) {
					max-width: 578px;
				}
			}
		}
	}

	h2{
		font-size: 48px;
		margin-bottom: 34px;

		@include media-breakpoint-up(lg) {
			font-size: 48px;
		}

		&:before,
		&:after{
			width: 10%;

			@include media-breakpoint-up(md) {
				width: 14%;
			}
			@include media-breakpoint-up(lg) {
				width: 24%;
			}
			@include media-breakpoint-up(xl) {
				width: 30%;
			}
		}
	}

	.services{
		display: flex;
		justify-content: space-between;
		padding: 24px 0 0 0;
		position: relative;

		@include media-breakpoint-down(sm) {
			flex-direction: column;
		}
		@include media-breakpoint-up(md) {
			padding-top: 26px;
		    margin-top: 136px;
		    padding-bottom: 80px;
		    background: #fff;
		    background-image: url(../images/home/icons_bg.png);
		    background-size: 100% 205px;
		    background-position: center top;
		    background-repeat: no-repeat;
		}
		@include media-breakpoint-up(xl) {
		    margin-left: -25px;
    		margin-right: -25px;
		}

		.main_v_line{
			height: 43px;
			width: 2px;
			position: absolute;
			left: 50%;
	        left: calc(50% - 1px);
    		margin-top: -111px;
		    background-color: #336699;

		    &:after{
		    	content: '';
		    	position: absolute;
		    	right: 0;
		    	top: 0;
		    	z-index: 2;
		    	background-color: #ca0015;
		    	width: 2px;
		    	height: 0;
		    	transition: height 300ms;
		    }

		    &.active:after{
		    	height: 100%;
		    }

		    @include media-breakpoint-down(sm) {
				display: none;
			}
		}

		.item{
			position: relative;

			@include media-breakpoint-down(sm) {
				margin-bottom: 44px;
			}
			@include media-breakpoint-up(md) {
				flex: 0 0 20%;
				max-width: 20%;
			}

			&.active{
				.icon{
					width: 128px;
					height: 219px;
					background-color: #ca0015;
					padding-bottom: 120px;
					margin-bottom: -110px;

					.next_icon{
						opacity: 1;
					}
				}
				span{
					color: #fff;

					br{
						display: inline-block;
					}
				}
			}

			a{
				text-decoration: none !important;
				color: #000;
			    display: block;
			    width: 128px;
			    margin-left: auto;
			    margin-right: auto;

				.icon{
					width: 109px;
					height: 109px;
					background-color: #336699;
					padding: 10px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-left: auto;
					margin-right: auto;
					cursor: pointer;
					transition: ease-out 400ms;

					.next_icon{
						opacity: 0;
						position: absolute;
						bottom: -30px;
						background-image: url(../images/home/icon_service_right.png);
						width: 20px;
						height: 20px;
						padding: 0;
						margin: 0;
						transition: ease-out 400ms;
					}
				}

				span{
					display: block;
					text-align: center;
					font-size: 21px;
					padding-top: 18px;
					white-space: nowrap;
					cursor: pointer;
					transition: ease-out 400ms;
				    margin-left: auto;
				    margin-right: auto;

					br{
						display: none;
					}
				}
			}


			&:nth-child(1){
				.h_line{
					right: -100%;
					width: 200%;

					&.active:after{
						right: 0%;
						width: 100%;
					}
				}

				a{
					width: 145px;
				}

				&.active{
					span.title{
						padding-top: 0;
					}
				}
			}
			&:nth-child(4){
				a{
					width: 185px;
				}

				&.active{
					span.title{
						padding-top: 0;
					}
				}
			}
			&:nth-child(5){
				.h_line{
					left: -150% !important;
					width: 200%;

					&.active:after{
						left: 0%;
						width: 100%;
					}
				}
			}

			.h_line{
			    position: absolute;
			    left: 50%;
			    left: calc(50% - 1px);
			    margin-top: -70px;
			    height: 2px;
			    width: 100%;
			    width: calc(100% + 1px);
			    background-color: #336699;

			    &:after{
			    	content: '';
			    	position: absolute;
			    	right: 0;
			    	top: 0;
			    	z-index: 2;
			    	background-color: #ca0015;
			    	height: 2px;
			    	width: 0;
			    	transition: width 300ms;
			    }

			    &.active:after{
			    	width: 100%;
			    }

			    &.right_side{
			    	left: -50%;
			    	left: calc(-50% - 1px);

			    	&:after{
			    		left: 0;
			    		right: auto;
			    	}
			    }

			    @include media-breakpoint-down(sm) {
					display: none;
				}
			}
			.v_line{
			    position: absolute;
			    left: 50%;
			    left: calc(50% - 1px);
		    	z-index: 1;
			    margin-top: -70px;
				height: 70px;
			    width: 2px;
			    background-color: #336699;

			    @include media-breakpoint-down(sm) {
					display: none;
				}

				&:after{
			    	content: '';
			    	position: absolute;
			    	left: 0;
			    	top: 0;
			    	z-index: 2;
			    	background-color: #ca0015;
			    	height: 0px;
			    	width: 2px;
			    	transition: height 300ms;
			    }

			    &.active:after{
			    	height: 100%;
			    }
			}
		}
	}
}