/*
Digital Sales
*/
.content.digital_sales{

	h1{
		&:before,
		&:after{
		    width: 10%;

		    @include media-breakpoint-up(md) {
				width: 28%;
			}
		    @include media-breakpoint-up(lg) {
				width: 33%;
			}
		    @include media-breakpoint-up(xl) {
				width: 36%;
			}
		}
	}

	.main_content{
		margin-bottom: 44px;

		@include media-breakpoint-up(lg) {
			margin-bottom: 84px;
			align-items: center;
		}

		& > div:last-child{
			img{
				margin-left: auto;
				margin-right: auto;
				display: block;
			}
		}
	}

	.services{
		margin-bottom: 44px;
		
		@include media-breakpoint-up(lg) {
			margin-bottom: 72px;
		}
		
		h2{
			@include media-breakpoint-down(sm) {
				font-size: 28px;
			}
			@include media-breakpoint-between(md, md) {
				font-size: 18px;
			}
			@include media-breakpoint-between(lg, lg) {
				font-size: 22px;
			}

			&:before,
			&:after{
				@include media-breakpoint-down(sm) {
					top: 14px;
				}
				@include media-breakpoint-between(md, md) {
					top: 8px;
				}
				@include media-breakpoint-between(lg, lg) {
					top: 12px;
				}
			}
		}

		img{
			margin-bottom: 18px;
			display: block;
			margin-left: auto;
			margin-right: auto;
		}

		& > div{
			@include media-breakpoint-down(sm) {
				margin-bottom: 36px;
			}

			&:nth-child(1){
				h2{
					&:before,
					&:after{
						width: 28%;
					}
				}
			}
			&:nth-child(2){
				h2{
					&:before,
					&:after{
						width: 10%;
					}
				}
			}
			&:nth-child(3){
				h2{
					&:before,
					&:after{
						width: 7%;
					}
				}
			}
		}
	}
	
}